export const LEAVE_MESSAGE_HEADING = 'Leave us a message';
export const LEAVE_MESSAGE_SUBHEADING = [
  'We’d like to know more about your specific problem or need.',
  'Don’t hesitate to get in touch.'
];
export const LEAVE_MESSAGE_SUBHEADING_MOBILE = [
  'We’d like to know more about',
  'your specific problem or need.',
  'Don’t hesitate to get in touch.'
];
export const LEAVE_MESSAGE_BUTTON_TEXT = 'Contact Us';
export const BLOG_LEAVE_MESSAGE_HEADING = 'Looking for efficient software solutions to drive your business’ digital transformation?';
export const BLOG_LEAVE_MESSAGE_SUBHEADING = [
  'We’re here to help you discover all the options and make them work for your exact case'
];
