import classNames from 'classnames';
import {navigate} from 'gatsby-link';
import PropTypes from 'prop-types';
import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Mail from '@material-ui/icons/MailOutlined';
import styles from './LeaveMessageBanner.module.scss';
import headerStyles from '../Header/Header.module.scss';
import {
  LEAVE_MESSAGE_BUTTON_TEXT,
  LEAVE_MESSAGE_HEADING,
  LEAVE_MESSAGE_SUBHEADING,
  LEAVE_MESSAGE_SUBHEADING_MOBILE
} from '../../constants/leave-message';

const LeaveMessageBanner = (props) => {
  const {
    buttonClassName, buttonText = LEAVE_MESSAGE_BUTTON_TEXT, className, heading = LEAVE_MESSAGE_HEADING,
    headingClassName, subHeading = LEAVE_MESSAGE_SUBHEADING, subHeadingClassName,
    subHeadingMobile = LEAVE_MESSAGE_SUBHEADING_MOBILE
  } = props;
  return <div className={classNames(styles['leave-message-banner'], className)}>
    <Typography
      className={classNames(styles['leave-message-heading'], headingClassName)}
      key="leave-message-heading"
      variant="h2"
    >
      {heading}
    </Typography>
    <div key="leave-message-subheading">
      {subHeading.map((subHeadingItem, index) => <Typography
        className={classNames(styles['leave-message-subheading'], subHeadingClassName)}
        key={`leave-message-subheading-${index}`}
        variant="h3"
      >
        {subHeadingItem}
      </Typography>)}
    </div>
    <div key="leave-message-subheading-mobile">
      {subHeadingMobile.map((subHeadingItem, index) => <Typography
        className={classNames(styles['leave-message-subheading-mobile'], subHeadingClassName)}
        key={`leave-message-subheading-mobile-${index}`}
        variant="h3"
      >
        {subHeadingItem}
      </Typography>)}
    </div>
    <div
      className={classNames(styles['leave-message-button-container'], buttonClassName)}
      key="leave-message-button-container"
    >
      <Button
        classes={{root: `${headerStyles['header__contact-us']} ${headerStyles['no-padding-media-query']}`}}
        color='primary'
        id='big-data_leave-message_contact-button'
        onClick={() => navigate('/contact-us')}
        startIcon={<Mail />}
        variant='contained'
      >
        {buttonText}
      </Button>
    </div>
  </div>
};

LeaveMessageBanner.propTypes = {
  buttonClassName: PropTypes.string,
  buttonText: PropTypes.string,
  className: PropTypes.string,
  heading: PropTypes.string,
  headingClassName: PropTypes.string,
  subHeading: PropTypes.array,
  subHeadingClassName: PropTypes.string,
  subHeadingMobile: PropTypes.array
};

export default LeaveMessageBanner;
