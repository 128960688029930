import React from 'react'
import styles from './VerticalCard.module.scss';
import Typography from '@material-ui/core/Typography';

const VerticalCard = ({classNumber, title, text, cardWidth}) => {
    return (<div className={styles[`block_${classNumber}`]} style={{width: cardWidth}}>
        <Typography variant="h1">{classNumber}</Typography>
        <div className={styles["block_dot-container"]}>
            <div className={styles["block_dot"]}/>
        </div>
        <Typography>{title}</Typography>
        <div className={styles["block_text"]}>
            <Typography variant="body2">{text}</Typography>
        </div>
    </div>)
}

export default VerticalCard;
