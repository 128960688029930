import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './Advantages.module.scss';
import BulletPointList from '../BulletPointList';
import {ADVANTAGES_HEADING} from '../../constants/advantages';

const Advantages = ({content = [], heading = ADVANTAGES_HEADING, listClassName}) => {
  return <div className={styles['advantages-list-container']}>
    <Typography align={'center'} className={styles['advantages-heading']} color={'secondary'} variant={'h2'}>
      {heading}
    </Typography>
    <BulletPointList
      cardContainerClassName={styles['advantages-list-card-container']}
      cardHeight="auto"
      cards={content}
      cardWidth="262px"
      className={classNames(styles['advantages-list'], listClassName)}
    />
  </div>;
};

Advantages.propTypes = {
  content: PropTypes.array.isRequired,
  heading: PropTypes.string,
  listClassName: PropTypes.string
};

export default Advantages;
