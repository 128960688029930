import React from 'react';
import Layout from '../components/Layout';
import { useSiteMetadata } from '../hooks';
import GCDF from '../components/GCDF';
import { GC_DATA_FUSION } from '../constants/services-names';
import {graphql, useStaticQuery} from "gatsby";

const GCDataFusion = ({ location }) => {
  const { subtitle: siteSubtitle } = useSiteMetadata();
  const { bg } = useStaticQuery(graphql`
    query {
      bg: allFile(filter: { sourceInstanceName: { eq: "preview" } name: { eq: "GCDF" } }) {
        nodes {
          name
          publicURL
        }
      }
    }
  `);
  return (
    <Layout
      title={GC_DATA_FUSION}
      description={siteSubtitle}
      location={location}
      socialImage={bg.nodes[0].publicURL}
    >
      <GCDF />
    </Layout>
  );
};

export default GCDataFusion;
