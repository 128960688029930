import classNames from "classnames";
import React from "react";
import styles from "./BulletPointList.module.scss";
import Divider from "@material-ui/core/Divider";
import VerticalCard from "../VerticalCard";

const BulletPointList = ({cardContainerClassName, cardHeight, cards, cardWidth, className}) => (
    <div className={classNames(styles['delivery__model'], className)}>
        <Divider className={styles['delivery__model_line']}/>
        {cards.map((item, index) => (
            <div key={index} className={classNames(styles['delivery__card-container'], cardContainerClassName)} style={{width: cardWidth, height: cardHeight}}>
                <VerticalCard classNumber={item.number} text={item.text} title={item.title}/>
            </div>
        ))}
    </div>
)

export default BulletPointList;
