import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import InnerPagesUpperBanner from '../InnerPagesUpperBanner';
import {
  GCDF_FEATURES, GCDF_FEATURES_HEADING, GCDF_HEADING, GCDF_INSIGHTS_HEADING, GCDF_SERVICES_HEADING, GCDF_SERVICES_LIST,
  GCDF_SERVICES_TEXT, GCDF_SUBHEADING, GCDF_WHAT_IS_GCDF_HEADING, GCDF_WHY_CV_CONTENT, GCDF_WHY_CV_HEADING,
  WHAT_IS_GCDF_LEFT_PADDING, WHAT_IS_GCDF_RIGHT_PADDING
} from '../../constants/google-cloud-data-fusion';
import Typography from '@material-ui/core/Typography';
import styles from './GCDF.module.scss';
import Advantages from '../Advantages';
import ExpandingCard from '../ExpandingCard';
import Feed from '../Feed';
import HorizontalCard from '../HorizontalCard';
import LeaveMessageBanner from '../LeaveMessageBanner';

const GCDF = () => {
  const {bg, icons, insightsPosts} = useStaticQuery(graphql`
      query {
          bg: allFile(
              filter: {
                  sourceInstanceName: { eq: "GCDF" }
                  name: { eq: "bg" }
              }
          ) {
              nodes {
                  name
                  publicURL
              }
          }
          icons: allFile(
              filter: {
                  sourceInstanceName: { eq: "GCDF" }
                  extension: { eq: "svg" }
              }
          ) {
              nodes {
                  name
                  publicURL
              }
          }
          insightsPosts: allMarkdownRemark(
              filter: {
                  fields: {
                      slug: {
                          in: ["/posts/building-a-modern-digital-enterprise-with-google-cloud-data-fusion", "/posts/google-cloud-migration-strategies-and-opportunities-beyond-usual-cloud-efficiencies", "/posts/10-reasons-why-it-outsourcing-in-ukraine-is-a-perfect-choice-for-global-businesses"]
                      }
                  }
                  frontmatter: {
                      template: {
                          eq: "post"
                      }
                      draft: {
                          ne: true
                      }
                  }
              }
          ) {
              nodes {
                  fields {
                      slug
                      categorySlug
                      tagSlugs
                  }
                  frontmatter {
                      title
                      date
                      category
                      description
                      socialImage
                      tags
                  }
              }
          }
      }
  `);

  const postsEdges = insightsPosts.nodes.map(node => ({node}));

  return (
    <div className={styles['services-gcdf']}>
      <InnerPagesUpperBanner
        className={styles['gcdf-banner']}
        bg={bg.nodes[0].publicURL}
        description={[GCDF_SUBHEADING]}
        descriptionClassName={styles['gcdf-banner-description']}
        text={[GCDF_HEADING]}
        textClassName={styles['gcdf-banner-text']}
      />

      <div className={styles['section-container']}>
        <div className={styles.container}>
          <Typography align="center" className={styles.heading} color="secondary" variant="h2">
            {GCDF_WHAT_IS_GCDF_HEADING}
          </Typography>

          <div className={styles['what-is-block-text']}>
            <Typography>{WHAT_IS_GCDF_LEFT_PADDING}</Typography>
            <Typography>{WHAT_IS_GCDF_RIGHT_PADDING}</Typography>
          </div>
        </div>
      </div>

      <div className={`${styles['section-container']} ${styles['cdf-features']} ${styles['non-white']}`}>
        <div className={styles.container}>
          <Typography align="center" className={styles.heading} color="secondary" variant="h2">
            {GCDF_FEATURES_HEADING}
          </Typography>
          <div className={styles['cdf-features-cards-block']}>
            {GCDF_FEATURES.map(({icon, title, text}) => (
              <React.Fragment key={title}>
                <div className={styles['card-container']}>
                  <HorizontalCard
                    icon={icons.nodes.find(({name}) => name === icon).publicURL}
                    title={title}
                    text={text}
                  />
                </div>
                <div className={styles['card-container-mobile']}>
                  <ExpandingCard
                    img={icons.nodes.find(({name}) => name === icon).publicURL}
                    smallCard
                  >
                    {{collapsedView: (
                        <>
                          <Typography
                            variant={'h4'}
                            style={{
                              fontSize: '14px',
                              lineHeight: '24px',
                              width: '100%'
                            }}
                          >
                            {title}
                          </Typography>
                        </>
                      ),
                      expandedView: (
                        <div className={'expanding-card-container'}>
                          <hr
                            style={{
                              background: '#DFE4ED'
                            }}
                          />
                          {text}
                        </div>
                      )}}
                  </ExpandingCard>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>

      <div className={`${styles['section-container']} ${styles['our-services']} ${styles['non-white']}`}>
        <div className={styles.container}>
          <Typography align="center" className={styles.heading} color="secondary" variant="h2">
            {GCDF_SERVICES_HEADING}
          </Typography>
          <p className={styles['services-subheading']}>{GCDF_SERVICES_TEXT}</p>
        </div>
      </div>

      <div className={styles['services-list-container']}>
        <div className={styles['services-columns-container']}>
          {GCDF_SERVICES_LIST.map(({items, title}, index) => {
            return <div className={styles['services-list-column']} key={`services-column-${index}`}>
              <div className={styles['services-list-column-title']}>{title}</div>
              <ul className={styles['services-list']}>
                {items.map((text, listItemIndex) => <li key={`services-list-item-${index}-${listItemIndex}`}>
                  <span className={styles['services-list-item']}>{text}</span>
                </li>)}
              </ul>
            </div>;
          })}
        </div>
      </div>

      <div className={`${styles['section-container']} ${styles['why-cv']}`}>
        <div className={styles.container}>
          <Advantages content={GCDF_WHY_CV_CONTENT} heading={GCDF_WHY_CV_HEADING} />
        </div>
      </div>

      <div className={`${styles['section-container']} ${styles['non-white']}`}>
        <div className={styles.container}>
          <Typography align="center" className={styles.heading} color="secondary" variant="h2">
            {GCDF_INSIGHTS_HEADING}
          </Typography>
          <Feed edges={postsEdges} />
        </div>
      </div>

      <LeaveMessageBanner />
    </div>
  );
};

export default GCDF;
